/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LayoutSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Layout = templateOnlyComponent<LayoutSignature>();

export default Layout;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountingHub::Layout': typeof Layout;
  }
}
