export default {
  "account-closing-banner": "cS",
  "vertical-center": "cc",
  "banner": "cq",
  "align-center": "cZ",
  "container": "cR",
  "illustration": "cQ",
  "img-container": "ce",
  "success-icon": "cB",
  "error-icon": "cE"
};
