export default {
  "bank-accounts": "ci l-app-content__wrapper",
  "header-wrapper": "cA",
  "scrolled-top": "cY",
  "header": "ch l-app-content__page-header",
  "statements-button": "cf btn",
  "add-account-button": "cK btn btn--primary",
  "make-transfer-button": "cG btn btn--primary",
  "main": "cr"
};
