/* import __COLOCATED_TEMPLATE__ from './account-statements-popup.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

export default class extends Component {
  spinner = Spinner;

  @service declare store: Services['store'];

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.loadStatementsTask.perform().catch(() => {
      // errors are handled by flash messages
    });
  }

  @action
  close() {
    // @ts-expect-error
    this.args.close();
    // @ts-expect-error
    this.args.data.onClose?.();
  }

  loadStatementsTask = task(async () => {
    // @ts-expect-error
    let { loadStatementsTask } = this.args.data;

    if (loadStatementsTask) {
      return await loadStatementsTask.linked().perform();
    } else {
      // @ts-expect-error
      let filters = this.args.data.bankAccountId
        ? // @ts-expect-error
          { bank_account_ids: [this.args.data.bankAccountId] }
        : undefined;

      return await this.store.query('statement', {
        // @ts-expect-error
        organization_id: this.args.data.organizationId,
        filters,
      });
    }
  });
}
