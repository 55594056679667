/* import __COLOCATED_TEMPLATE__ from './account-details-download.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface AccountDetailsDownloadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountDetailsDownloadComponent extends Component<AccountDetailsDownloadSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare bankAccount: Services['bankAccount'];

  get organizationLocale() {
    // @ts-expect-error
    return this.args.bankAccount.organization.legalCountry.toLowerCase();
  }

  get sortedLocales() {
    // @ts-expect-error
    return this._sortLocales(this.intl.getLocaleCodes, this.organizationLocale);
  }

  // @ts-expect-error
  _sortLocales(allLocales, orgLocale) {
    // @ts-expect-error
    return allLocales.sort((a, b) => {
      // orgLocale always comes first
      if (a === orgLocale) {
        return -1;
      }

      if (b === orgLocale) {
        return 1;
      }

      // english comes before all other locales
      if (a === 'en') {
        return -1;
      }

      if (b === 'en') {
        return 1;
      }

      // otherwise, sort alphabetically considering
      // there are no equal locales
      return a < b ? -1 : 1;
    });
  }

  handleIbanDownloadTask = dropTask(async locale => {
    this.trackLocaleDownload(locale);
    // @ts-expect-error
    await this.bankAccount.downloadIbanPdf(this.args.bankAccount.id, locale);
  });

  @action
  // @ts-expect-error
  trackLocaleDownload(locale) {
    if (locale === this.organizationLocale) {
      this.segment.track('account_details_download_local');
    } else {
      this.segment.track(`account_details_download_${locale}`);
    }
  }

  @action
  // @ts-expect-error
  downloadLinkLabel(locale) {
    return this.intl.t(`bank_accounts.share.download.link-${locale}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountDetailsDownload: typeof AccountDetailsDownloadComponent;
  }
}
