/* import __COLOCATED_TEMPLATE__ from './account-creation-blocked-italy-popup.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountCreationBlockedItalyPopupSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountCreationBlockedItalyPopup =
  templateOnlyComponent<AccountCreationBlockedItalyPopupSignature>();

export default AccountCreationBlockedItalyPopup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountCreationBlockedItalyPopup: typeof AccountCreationBlockedItalyPopup;
  }
}
