/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface AccountsLayoutSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class AccountsLayoutComponent extends Component<AccountsLayoutSignature> {
  @service declare abilities: Services['abilities'];

  constructor(owner: unknown, args: AccountsLayoutSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.maybeScrollToLast) {
      next(() => {
        // @ts-expect-error
        this.args.maybeScrollToLast(document.getElementById('bank-account-list'));
      });
    }
  }

  get scrolledTop() {
    // @ts-expect-error
    return this.args.scrolledTop;
  }

  get hasAccounts() {
    // @ts-expect-error
    return this.args.hasAccounts ?? true;
  }

  @action onCreateAccount() {
    if (this.abilities.cannot('create account')) return;

    // @ts-expect-error
    this.args.onCreateAccount();
  }

  // @ts-expect-error
  @action onUpdateScrolledTop(value) {
    // @ts-expect-error
    return this.args.onUpdateScrolledTop?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Accounts::Layout': typeof AccountsLayoutComponent;
  }
}
