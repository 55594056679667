/* import __COLOCATED_TEMPLATE__ from './email-form-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

interface AllowedEmailFormModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AllowedEmailFormModal extends Component<AllowedEmailFormModalSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked email = this.args.data.allowedEmail.email;

  get title() {
    // @ts-expect-error
    return this.args.data.allowedEmail.isNew
      ? this.intl.t('settings.invoice-receipt-email.add-email-modal.title')
      : this.intl.t('settings.invoice-receipt-email.edit-email-modal.title');
  }

  get hasErrors() {
    return (
      Boolean(this.saveEmailTask.performCount) &&
      // @ts-expect-error
      this.args.data.allowedEmail.validations.attrs.email.isInvalid
    );
  }

  saveEmailTask = dropTask(async () => {
    this.segment.track('receipt-forward_email-whitelisted_clicked');
    // @ts-expect-error
    await this.args.data.saveEmail(this.email, this.args.close);
  });

  @action
  // @ts-expect-error
  valueChanged(event) {
    this.email = event.target.value;
  }

  @action
  willDestroy() {
    super.willDestroy();
    // @ts-expect-error
    this.args.data.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AllowedEmails::EmailFormModal': typeof AllowedEmailFormModal;
  }
}
