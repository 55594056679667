/* import __COLOCATED_TEMPLATE__ from './organization-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OrganizationItemSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const OrganizationItem = templateOnlyComponent<OrganizationItemSignature>();

export default OrganizationItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountingHub::OrganizationItem': typeof OrganizationItem;
  }
}
