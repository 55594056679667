/* import __COLOCATED_TEMPLATE__ from './address-form-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AddressFormModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AddressFormModal = templateOnlyComponent<AddressFormModalSignature>();

export default AddressFormModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AddressFormModal: typeof AddressFormModal;
  }
}
