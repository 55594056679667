/* import __COLOCATED_TEMPLATE__ from './account-creation-blocked-popup.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountCreationBlockedPopupSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountCreationBlockedPopup = templateOnlyComponent<AccountCreationBlockedPopupSignature>();

export default AccountCreationBlockedPopup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountCreationBlockedPopup: typeof AccountCreationBlockedPopup;
  }
}
