/* import __COLOCATED_TEMPLATE__ from './app-content.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AppContentSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AppContent = templateOnlyComponent<AppContentSignature>();

export default AppContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppContent: typeof AppContent;
  }
}
