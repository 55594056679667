/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

interface AccountClosingBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountClosingBanner extends Component<AccountClosingBannerSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare flow: Services['flow'];

  get planName() {
    return this.subscriptionManager.currentPricePlan?.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get accountAuthorizedBalanceZero() {
    // @ts-expect-error
    return this.args.requirements.accountAuthorizedBalanceZero;
  }

  constructor(owner: unknown, args: AccountClosingBannerSignature['Args']) {
    super(owner, args);
    this.trackEvent();
  }

  trackEvent() {
    let {
      accountBalanceZero,
      noActiveSavingsAccount,
      balanceEqualToAuthorizedBalance,
      noPendingChecks,
      // @ts-expect-error
    } = this.args.requirements;

    let requirementsFulfilled =
      accountBalanceZero &&
      noActiveSavingsAccount &&
      balanceEqualToAuthorizedBalance &&
      noPendingChecks;

    let status = 'Closing requirements KO';
    // @ts-expect-error
    if (this.args.requester === 'qonto') {
      status = 'Qonto closure';
    } else if (requirementsFulfilled) {
      status = 'Closing requirements OK';
    }

    this.segment.track('company_profile_closure_banner', {
      status,
    });
  }

  get requirements() {
    let balanceRequirement = {
      name: 'balance',
      title: this.intl.t('subscription.account-closing.requirements.balance'),
      // @ts-expect-error
      isFullfilled: this.args.requirements.accountBalanceZero,
    };

    let pendingPaymentsRequirement = {
      name: 'pending-payments',
      title: this.intl.t('subscription.account-closing.requirements.pending-payments'),
      isFullfilled: this.noPendingPayments,
    };

    let termDepositRequirement = {
      name: 'term-deposit',
      title: this.intl.t('subscription.account-closing.requirements.no-term-deposit'),
      isFullfilled: false,
    };

    let requirements = [
      balanceRequirement,
      pendingPaymentsRequirement,
      // @ts-expect-error
      ...(!this.args.requirements.noActiveSavingsAccount ? [termDepositRequirement] : []),
    ];

    if (
      variation('feature--boolean-account-closing-refund') &&
      this.organizationManager.organization.isItalian
    ) {
      requirements = [
        {
          ...balanceRequirement,
          title: this.intl.t('subscription.account-closing.requirements.balance-italy'),
        },
        {
          ...pendingPaymentsRequirement,
          title: this.intl.t('subscription.account-closing.requirements.pending-payments-italy'),
        },
      ];
    }

    return requirements;
  }

  get isItalianOrganization() {
    return this.organizationManager.organization.isItalian;
  }

  get closingDateFormatted() {
    // @ts-expect-error
    return this.intl.formatDate(this.args.closingDate, {
      format: 'long',
      locale: this.intl.primaryLocale,
    });
  }

  get noPendingPayments() {
    // @ts-expect-error
    let { balanceEqualToAuthorizedBalance, noPendingChecks } = this.args.requirements;
    return balanceEqualToAuthorizedBalance && noPendingChecks;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountClosing::Banner': typeof AccountClosingBanner;
  }
}
