/* import __COLOCATED_TEMPLATE__ from './account-selector.hbs'; */
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

interface AccountSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class AccountSelectorComponent extends Component<AccountSelectorSignature> {
  badge = Badge;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountSelector: typeof AccountSelectorComponent;
  }
}
