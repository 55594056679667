/* import __COLOCATED_TEMPLATE__ from './account-statements-row.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountStatementsRowSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountStatementsRow = templateOnlyComponent<AccountStatementsRowSignature>();

export default AccountStatementsRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountStatementsRow: typeof AccountStatementsRow;
  }
}
